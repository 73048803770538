import http from "../utils/http"

/**
 * 订单列表
 * @param {object} params
 */
export function memberOrderPageList(params) {
    return http({
        url: "/api/Bulkorder/getMemberOrderPageList",
        data: params,
        forceLogin: true
    })
}

/**
 * 申请物权
 * @param {object} params
 */
export function applyRealright(params) {
    return http({
        url: "/api/Bulkorderrealright/applyRealright",
        data: params,
        forceLogin: true
    })
}
//大宗订单详情
export function memberOrderDetail(params) {
    return http({
        url: "/api/Bulkorder/orderDetail",
        data: params,
        forceLogin: true
    })
}
//审核订单
export function bulkorderexamineList(params) {
    return http({
        url: "/api/Bulkorderexamine/getBulkorderexamineList",
        data: params,
        forceLogin: true
    })
}
//订单通过审核
export function orderExaminePass(params) {
    return http({
        url: "/api/Bulkorderexamine/orderExaminePass",
        data: params,
        forceLogin: true
    })
}
//订单拒绝审核
export function contractRefuse(params) {
    return http({
        url: "/api/Bulkorderexamine/contractRefuse",
        data: params,
        forceLogin: true
    })
}
//合同审核列表
export function contractExaminePageList(params) {
    return http({
        url: "/api/Bulkordercontract/getContractExaminePageList",
        data: params,
        forceLogin: true
    })
}
//合同审核通过
export function contractMemeberPass(params) {
    return http({
        url: "/api/Bulkordercontract/contractMemeberPass",
        data: params,
        forceLogin: true
    })
}
//合同审核拒绝
export function contractMemeberRefuse(params) {
    return http({
        url: "/api/Bulkordercontract/contractMemeberRefuse",
        data: params,
        forceLogin: true
    })
}
//合同列表
export function orderContractPageList(params) {
    return http({
        url: "/api/Bulkordercontract/getOrderContractPageList",
        data: params,
        forceLogin: true
    })
}
//合同详情
export function contractDownloadFile(params) {
    return http({
        url: "/api/Bulkordercontract/downloadFile",
        data: params,
        forceLogin: true
    })
}
//合同签署回调
export function orderSign(params) {
    return http({
        url: "/api/Bulkordercontract/orderSign",
        data: params,
        forceLogin: true
    })
}
//付款列表
export function OrderPayplanPageList(params) {
    return http({
        url: "/api/Bulkorderpayplam/getOrderPayplanPageList",
        data: params,
        forceLogin: true
    })
}
//支付信息获取
export function OrderpayConfig(params) {
    return http({
        url: "/api/Bulkorderpayplam/getOrderpayConfig",
        data: params,
        forceLogin: true
    })
}
//去支付
export function savePaymentVoucher(params) {
    return http({
        url: "/api/Bulkorderpayplam/savePaymentVoucher",
        data: params,
        forceLogin: true
    })
}
//支付详情
export function PlanInfo(params) {
    return http({
        url: "/api/Bulkorderpayplam/getPlanInfo",
        data: params,
        forceLogin: true
    })
}
//发货列表
export function shippingPlamOrderPageList(params) {
    return http({
        url: "/api/Bulkordershippingplam/getShippingPlamOrderPageList",
        data: params,
        forceLogin: true
    })
}
//确认收货
export function confirmDlivery(params) {
    return http({
        url: "/api/Bulkordershippingplam/confirmDlivery",
        data: params,
        forceLogin: true
    })
}
//发货详情
export function ShippingOrderDetails(params) {
    return http({
        url: "/api/Bulkordershippingplam/getShippingOrderDetails",
        data: params,
        forceLogin: true
    })
}
//发票列表
export function OrderInvoicePageList(params) {
    return http({
        url: "/api/Bulkorderinvoice/getOrderInvoicePageList",
        data: params,
        forceLogin: true
    })
}
//申请发票
export function addInvoice(params) {
    return http({
        url: "/api/Bulkorderinvoice/addInvoice",
        data: params,
        forceLogin: true
    })
}
//发票详情
export function OrderInvoiceInfo(params) {
    return http({
        url: "/api/Bulkorderinvoice/geOrderInvoiceInfo",
        data: params,
        forceLogin: true
    })
}
//异常订单列表
export function terminateOrderPageList(params) {
    return http({
        url: "/api/Bulkorder/getTerminateOrderPageList",
        data: params,
        forceLogin: true
    })
}


//去签订
export function getSignUrl(params) {
    return http({
        url: "/api/Bulkordercontract/getSignUrl",
        data: params,
        forceLogin: true
    })
}


//合同详情
export function contractlnfo(params) {
    return http({
        url: "/api/Bulkordercontract/contractinfo",
        data: params,
        forceLogin: true
    })
}

//合同详情
export function getPayinfo(params) {
    return http({
        url: "/helipay/api/Orderpay/orderLargePayment",
        data: params,
        forceLogin: true
    })
}

//导出订单
export function exportMembreGoods(params) {
    return http({
        url: "/api/Bulkorder/exportMembreGoods",
        data: params,
        forceLogin: true
    })
}
//合利宝支付
export function onlineinterface(params) {
    return http({
        url: "/helipay/api/Orderpay/onlineinterface",
        data: params,
        forceLogin: true
    })
}
//合利宝支付银行列表
export function payType(params) {
    return http({
        url: "/helipay/api/Orderpay/payType",
        data: params,
        forceLogin: true
    })
}
