import { apiOrderPay, apiOrderClose, apiOrderTakedelivery,orderExtend } from "@/api/order/order"
import { orderExaminePass, contractRefuse,contractMemeberPass,contractMemeberRefuse } from "@/api/bulkOrder"

export default {
    methods: {
        /**
         * 订单支付
         * @param {Object} out_trade_no
         */
        orderPay(orderData) {
            if (orderData.adjust_money == 0) {
                apiOrderPay({
                    order_ids: orderData.order_id
                }).then(res => {
                    if (res.code >= 0) {
                        this.$router.push({
                            path: "/pay",
                            query: {
                                code: res.data
                            }
                        })
                    } else {
                        this.$message({
                            message: res.message,
                            type: "warning"
                        })
                    }
                })
				.catch(err=>{
					this.$message.error(err.message);
				})
            } else {
				apiOrderPay({
				    order_ids: orderData.order_id
				}).then(res => {
				    if (res.code >= 0) {
				        this.$router.push({
				            path: "/pay",
				            query: {
				                code: res.data
				            }
				        })
				    } else {
				        this.$message({
				            message: res.message,
				            type: "warning"
				        })
				    }
				})
				.catch(err=>{
					this.$message.error(err.message);
				})
                // this.$confirm("商家已将支付金额调整为" + orderData.pay_money + "元，是否继续支付？", "提示", {
                //     confirmButtonText: "确定",
                //     cancelButtonText: "取消",
                //     type: "warning"
                // }).then(() => {
                   
                // })
            }
        },
        /**
         * 关闭订单
         * @param {Object} order_id
         */
        orderClose(order_id, callback) {
            this.$confirm("您确定要关闭该订单吗？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                apiOrderClose({
                    order_id
                }).then(res => {
					if(res.code>=0){
						this.$message({
						    message: "订单关闭成功",
						    type: "success"
						})
						typeof callback == "function" && callback()
					}else{
						this.$message.error(res.message)
					}
                })
				.catch(err=>{
					this.$message.error(err.message)
				})
            })
        },
		//合同审核通过
		contractMemeberPass(order_id, callback) {
		    this.$confirm("您确定该合同审核通过吗？", "提示", {
		        confirmButtonText: "确定",
		        cancelButtonText: "取消",
		        type: "warning"
		    }).then(() => {
		        contractMemeberPass({
		            order_id
		        }).then(res => {
					if(res.code>=0){
						this.$message({
						    message: "审核通过成功",
						    type: "success"
						})
						typeof callback == "function" && callback()
					}else{
						this.$message.error(res.message)
					}
		            
		        })
				.catch(err=>{
					this.$message.error(err.message)
				})
		    })
		},
		//合同审核拒绝
		contractMemeberRefuse(order_id, callback) {
		    this.$confirm("您确定该合同审核拒绝吗？", "提示", {
		        confirmButtonText: "确定",
		        cancelButtonText: "取消",
		        type: "warning"
		    }).then(() => {
		        contractMemeberRefuse({
		            order_id
		        }).then(res => {
					if(res.code>=0){
						this.$message({
						    message: "审核拒绝成功",
						    type: "success"
						})
						typeof callback == "function" && callback()
					}else{
						this.$message.error(res.message)
					}
		        })
				.catch(err=>{
					this.$message.error(err.message)
				})
		    })
		},
		//订单审核通过
		ExaminePass(order_id, callback) {
		    this.$confirm("您确定审核通过吗？", "提示", {
		        confirmButtonText: "确定",
		        cancelButtonText: "取消",
		        type: "warning"
		    }).then(() => {
		        orderExaminePass({
		            order_id
		        }).then(res => {
					if(res.code>=0){
						this.$message({
						    message: "审核通过成功",
						    type: "success"
						})
						typeof callback == "function" && callback()
					}else{
						this.$message.error(res.message)
					}
		        })
				.catch(err=>{
					this.$message.error(err.message)
				})
		    })
		},
		//订单审核拒绝
        /**
         * 订单收货
         * @param {Object} order_id
         */
        ExamineRefuse(order_id, callback) {
            this.$confirm("您确定审核拒绝吗？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                contractRefuse({
                    order_id
                }).then(res => {
					if(res.code>=0){
						this.$message({
						    message: "审核拒绝成功",
						    type: "success"
						})
						typeof callback == "function" && callback()
					}else{
						this.$message.error(res.message)
					}
                    
                })
				.catch(err=>{
					this.$message.error(err.message)
				})
            })
        },
		/**
		 * 延长收货
		 * @param {Object} order_id
		 */
		orderExtendDelivery(orderData, callback) {
		    this.$confirm("您确定延长收货时间吗？", "提示", {
		        confirmButtonText: "确定",
		        cancelButtonText: "取消",
		        type: "warning"
		    }).then(() => {
		       orderExtend({
		            order_id: orderData.order_id
		        }).then(res => {
					if (res.code >= 0) {
						this.$message({
						    message: "订单延长收货成功",
						    type: "success"
						})
					} else {
					    this.$message({
					        message: res.message,
					        type: "warning"
					    })
					}
					 typeof callback == "function" && callback()
		        })
		    })
		}
    }
}
